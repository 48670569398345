.mt-36 {
    margin-top: 3.6rem;
}

.mb-36 {
    margin-bottom: 3.6rem;
}

.inte-logo {
    border-radius: 11.25px;
    background: linear-gradient(180deg, #FFF 0%, #D0D5DD 100%);
}

.inte--white span {
    color: var(--shades-g-0, #FFF) !important
}

.pt40{
    padding: 40px 0;
}
.border-btm{
    
        border-bottom: 0.1rem solid var(--inte-G40)!important;
        background-color: var(--inte-G0)!important;
    
}