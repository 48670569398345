body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div[id^="inte-sheet"]
  + .inte-mobileDevice--in
  div[id^="inte-sheet"]
  + .inte-mobileDevice--out {
  z-index: 900;
}
div[id^="inte-sheet"] ~ div.inte-select__overlay {
  z-index: 800;
}
