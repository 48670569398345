.Custom-badge {
    display: flex;
    width: 122px;
    height: 28px;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    color: #fff;
    background: linear-gradient(142deg, #431BBC 0%, #1570EF 100%);
}

.custom-button_center .inte-carousel__previousNextArrow {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 291px;
    pointer-events: none;
}

.custom-button_center .inte-carousel__previousNextArrow .inte-carousel__prev,
.custom-button_center .inte-carousel__previousNextArrow .inte-carousel__next {
    background-color: #fff;
    pointer-events:auto;
}

.toggle-switch {
    cursor: pointer;
    border: 0;
    position: relative;
    width: 254px;
    height: 4rem;
    border-radius: 2rem;
    display: flex;
    border: .1rem solid var(--inte-G40);
    align-items: center;
}

.inte-toggles {
    position: absolute;
    background: #f4f2fa;
    height: 100%;
    width: 50%;
    border: 1px solid #7a5dcf;
    border-radius: 20px;
}
.inte-monthly,
.inte-yearly{
    display: flex;
    width: 50%;
    justify-content: center;
    padding: 8px 16px 8px 16px;
}

.inte-toggles__monthly {
    left: 0;
}

.inte-toggles__yearly {
    right: 0;
}

.inte-toggles__monthly~.inte-monthly,
.inte-toggles__yearly~.inte-yearly {
    color: #431BBC;
    font-weight: var(--weight-bolder);
}

.inte-monthly,
.inte-yearly {

    z-index: 1;

}