* {
  margin: 0;
  padding: 0;
}
.inte-loginRegister {
  height: 100vh;
  display: flex;
  overflow: auto;
}

.inte-auth__leftSide {
  width: 50%;
  background: linear-gradient(205.53deg, #413bbc -51.17%, #000000 115.51%);
}
.inte-auth__rightSide {
  width: 50%;
  background-color: var(--inte-G0);
  overflow: auto;
}

.inte-auth__rightBanner {
  width: 100%;
  padding: 7rem 2.4rem;
  max-width: 50rem;
  margin: 0 auto;
}
.inte-auth__leftBanner {
  padding: 7rem;
  height: 100%;
}
.inte-auth__leftBanner .inte-text--default {
  color: var(--inte-G0) !important;
}

.inte-auth__social--description.inte-text--fontBolder,
.inte-auth__social--footer.inte-text--fontBolder {
  font-weight: 400 !important;
}

.inte-auth__social--footer {
  position: absolute;
  bottom: 7rem;
  line-height: 2rem !important;
}
.inte-auth__heading {
  padding: 44px 0 24px 0;
}
.inte-auth__heading > .inte-text--T1 {
  font-weight: 700 !important;
  line-height: 4.8rem;
}
.inte-auth__logo {
  display: flex;
}

/* reponsive for mobile device */
@media only screen and (max-width: 768px) {
  .inte-auth__heading {
    padding: unset;
  }
  .inte-auth__heading > .inte-text--T1 {
    font-weight: 800 !important;
    line-height: 2.8rem;
  }
  .inte-auth__logo {
    padding-right: 16px;
  }
  .inte-auth__leftSide,
  .inte-auth__rightSide {
    width: 100%;
  }
  .inte-auth__leftBanner {
    padding: 50px 44px;
    display: flex;
    align-items: center;
  }

  .inte-auth__rightSide {
    overflow: unset;
  }
  .inte-loginRegister {
    flex-direction: column;
  }
  .inte-auth__rightBanner {
    padding: 2.4rem 1.6rem;
    max-width: unset;
  }
}

@media only screen and (max-width: 880px) {
  .inte-auth__social--footer {
    font-size: 13px;
  }
}
