.inte-tabs--vertical.inte-tabs__customClass .inte-tabs {
  border: 0.1rem solid var(--inte-G40) !important;
  border-radius: var(--radius-rounded-8) !important;
  padding: 2rem;
}
.inte-customTikTok__textField {
  margin-left: 3.2rem;
}

.inte-tabs__customClass .inte-tabs .inte-badge-radio {
  margin-top: 0.8rem;
}
.inte-badge-radio .inte-badge__content {
  white-space: unset;
}
.inte-editProduct__thumbnail {
  position: relative;
}
.inte-editProduct__thumbnail:hover {
  cursor: pointer;
}
.inte-editProduct__thumbnail .inte-thumbnail__wrapper {
  width: 104px;
  height: 104px;
  display: flex;
}
.inte-editProduct__text {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  margin: 0.8rem;
  align-items: center;
  justify-content: space-between;
}
.inte-editProduct__overLay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0000007d;
  border-radius: 0.8rem;
}
.inte-editProduct__thumbnail .inte-checkboxWrap .inte__checkboxEle--fake {
  border: 0.2rem solid var(--inte-G0) !important;
  background-color: unset !important;
}

.inte-editProduct__thumbnail
  .inte-checkbox--fake:focus
  + .inte-checkboxWrap
  .inte__checkboxEle--fake {
  box-shadow: unset !important;
}

.inte-tabs--vertical .inte-tabs__contentBox {
  width: calc(100% - 25rem);
}
