.inte-customTable {
  border: 0.1rem solid var(--inte-G40);
  border-radius: 0.8rem;
}
.inte-customTable__items {
  padding: 1.6rem;
  border-bottom: 1px solid var(--inte-G40);
}
.inte-customTable .inte-viewProduct {
  border: unset;
  border-radius: unset;
}
