/* View Products css start  */
.inte-custom__pageHeader {
  padding-top: unset !important;
  padding-bottom: 3.2rem !important;
}
.inte-custom__tabs .inte-tabs {
  margin-bottom: 2.4rem;
}
.inte-custom__thumbnail {
  height: 20.8rem !important;
  width: 100% !important;
}
.inte-custom__thumbnail {
  object-fit: contain;
}
/* custom tags  in product view*/
.inte-custom__tag {
  border: 1px solid #d7d9db;
  border-radius: 2rem;
  display: flex;
  align-items: center;
}
.inte-custom__tag .inte-custom__tagText {
  padding: 0.2rem 1.2rem;
}
.inte-custom__tagValue {
  background-color: #ecedee;
  display: flex;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0.2rem 1.2rem;
}
/* view img  */
.inte-thumbnail__container {
  position: relative;
  height: 6.4rem;
  width: 6.4rem;
  margin: 0 auto;
  cursor: pointer;
}

.inte-thumbnail__overLay {
  position: absolute;
  background-color: #00000085;
  top: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  font-size: 2.6rem;
  left: 0;
  border-radius: var(--radius-rounded-6);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* View Products css end  */

/* order Grid css Start  */
.inte-btn--onlyIcon.inte-btn--large.inte-button__custom {
  width: unset !important;
}
.inte-thumbnail__mobile {
  height: 11.1rem;
  width: 11.1rem;
}
.inte-thumbnail__mobile .inte-thumbnail--large {
  height: 11.1rem;
  width: 11.1rem;
}
/* order Grid css end  */

/* select or check grid / table  */

.inte-selectButton,
.inte-selectButton__wrapper,
.inte-selectButton__text,
.inte-grid__selectButton {
  display: flex;
  align-items: center;
}
.inte-grid__selectButton,
.inte-selectButton__wrapper {
  justify-content: space-between;
}
.inte-selectButton__wrapper {
  height: 4.4rem;
  border-radius: var(--radius-rounded-6);
  margin-right: 1.6rem;
  transition: 300ms all linear;
}
.inte-selectButton__wrapper:hover {
  cursor: pointer;
  background-color: var(--inte-G30);
}

.inte-selectButton__text {
  border: 0.1rem solid var(--inte-G50);
  height: 100%;
  padding: 0.8rem 1.2rem;
  border-top-left-radius: var(--radius-rounded-6);
  border-bottom-left-radius: var(--radius-rounded-6);
}
.inte-selectButton__text .inte-checkbox {
  margin-right: 1.2rem;
}
.inte-selectButton__text .inte-text--T7 {
  white-space: pre;
}
.inte-selectButton__icon {
  display: flex;
  border-right: 0.1rem solid var(--inte-G50);
  border-top: 0.1rem solid var(--inte-G50);
  border-bottom: 0.1rem solid var(--inte-G50);
  border-top-right-radius: var(--radius-rounded-6);
  border-bottom-right-radius: var(--radius-rounded-6);
  height: 100%;
  align-items: center;
  height: 44px;
  width: 44px;
  justify-content: center;
}
.inte-Fallback--illustration > div {
  height: 200px;
  width: 200px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .inte-selectButton {
    width: 100%;
    justify-content: space-between;
  }
  .inte-selectButton .inte-selectButton__wrapper {
    margin-right: unset;
  }
}
