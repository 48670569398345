.inte-statusCard--green {
  --col : var(--inte-GR110)
}

.inte-statusCard--red {
 --col : var(--inte-R300)
}

.inte-statusCard--yellow {
  --col : var(--inte-Y300)
}

.inte-statusCard--black {
  --col : var(--inte-G800)
}
.inte-statusCard {
  box-shadow: -4px 0px 0px 0px var(--col) !important;
}

.inte-statusCard .inte-card__header > .inte-svgIcon {
  left: 0 !important;
  transition: left 300ms ease-in !important;
}

.inte-statusCard:hover .inte-card__header > .inte-svgIcon {
  left: 10px !important;
  transition: left 300ms ease-in !important;
}

.inte-statusCard .inte-card__headerTitle {
  color: var(--col) !important;
}



