.inte-custom__pageHeader {
  padding-top: unset !important;
  padding-bottom: 3.2rem !important;
}
.inte-btn--onlyIcon.inte-btn--large.inte-button__custom {
  width: unset !important;
}
.inte-custom__tabs .inte-tabs {
  margin-bottom: 2.4rem;
}
/* view product  */
.inte-viewProduct {
  border-radius: 0.8rem;
  border: 0.1rem solid var(--inte-G40);
  background-color: var(--inte-G0);
}
.inte-viewProduct__totalCount {
  display: flex;
  padding: 20px;
  justify-content: end;
}
.inte-viewProduct__footer {
  background-color: var(--inte-G10);
  border-top: 1px solid var(--inte-G40);
  padding: 20px;
  display: flex;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  justify-content: end;
}
.inte-viewProduct__totalCount .inte-flex:first-child,
.inte-viewProduct__footer p:first-child {
  margin-right: 4rem !important;
}
.inte-viewProduct__footer p:first-child {
  width: 12.4rem;
}
@media screen and (max-width: 768px) {
  .inte-viewProduct__totalCount,
  .inte-viewProduct__footer {
    justify-content: space-between;
  }
  .inte-viewProduct__totalCount .inte-flex:first-child,
  .inte-viewProduct__footer p:first-child {
    margin-right: unset;
  }
}
