.inte__p--0 > .inte-card__body{
  padding: 0!important;
}
.inte-no-spacing + .inte-card{
  margin-top: 0!important;
}

.header-card .inte-card__header{
  background-color: var(--inte-G10);
  border-radius: var(--radius-rounded-8);
  padding-bottom: 1.6rem !important;
}

.header-card .inte-card__body {
  border-top: 1px solid var(--inte-G40);
}

.inte-svg--inline-flex {
  display: inline-flex !important;
  cursor: pointer;
}