.inte-auth_headerDevider {
  margin-top: 24px;
}
.inte-auth__wrapper {
  padding-top: 44px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inte-auth_userHeading {
  line-height: 3rem !important;
}
.inte-register__forgotButton,
.inte-login__forgotButton {
  display: flex !important;
  justify-content: end;
}
.inte-login__forgotButton {
  padding-bottom: 20px;
}
.inte-register__checkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inte-auth__backLogin {
  margin-top: 2rem;
}
.inte-register__signInButton {
  padding-top: 2.4rem;
}
.inte-register__signInButton,
.inte-login__registerButton {
  display: flex;
  justify-content: center;
}
.inte-register__signInButton .inte-textLink :first-child,
.inte-login__registerButton .inte-textLink :first-child {
  padding-left: 4px;
}
.inte-auth__condation--wrapper,
.inte-auth__condation {
  display: flex;
}
.inte-auth__condation {
  gap: 8px;
  flex-direction: column;
}
.inte-auth__condation li {
  color: #4e4f52;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
}
.inte-auth__condation--wrapper li {
  display: flex;
  padding-right: 0.8rem;
}
.inte-auth__bottomSeprator {
  margin: 2rem 0 2.4rem 0;
}
@media only screen and (max-width: 768px) {
  .inte-auth__wrapper {
    padding-top: 2.4rem;
  }
}
