.donught-chart {
    position: relative;
}

.donught-chart canvas {
    margin: auto;
    width: 200px;
    height: 200px;
}

.donughtCard-center-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:4px;
}
