.required-card .inte-card__headerTitle::after {
    content: "*";
    color: var(--inte-R400);
}

.background-card.inte-card{
    background-color: #F5F6F6;
}

.inte-underlineText--dashed {
    width: 100%;
    margin-bottom: 0.1rem;
    border-bottom: 0.1rem dashed var(--inte-G800);
}

.inte-text--default.inte-text-whiteCol {
    color: var(--inte-G0) !important;
}